<template>
  <div class="warning-pop-container" v-if="show_pop">
    <div class="striped-border">
      <div class="close-icon" @click="closePop">x</div>
    </div>

    <div class="warning-content-container flex-between">
      <div>
        <div class="warn-text-container">
          <div class="warn-text">警告！发现充电车辆温度过高</div>
          <div class="warn-text-tips">如温度持续升高将触发自动喷淋系统，请尽快排查现场情况</div>
        </div>
        <div class="flex-first mt14">
          <!--          <div class="device-temperature ml32">80°C</div>-->
          <div class="font-20 font-weight-700 ml12 grid-first" style="height: 70px;">
            <div class="warning-charge-station">
              充电桩：{{ fire_info.ChargeStation.street + fire_info.ChargeStation.name }}
            </div>
            <!--            <div class="warning-charge-station">充电桩：某区域-某街道充电桩</div>-->
            <div class="warning-station-address">
              地址：{{
                fire_info.ChargeStation.district + fire_info.ChargeStation.street + fire_info.ChargeStation.communities + fire_info.ChargeStation.roads + fire_info.ChargeStation.address
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="text-center" style="width: 30%;">
        <div class="warning-cctv-pic">
          <a :href="fire_info.screenshot" target="_blank">
            <img :src="fire_info.screenshot" alt="" class="warning-cctv-pic"/>
          </a>
        </div>
        <div class="font-20">充电桩监控画面</div>
      </div>
    </div>
    <div class="striped-border"></div>
  </div>
</template>
<script>
import cache from "@/utils/cache";
import {XTask} from "@/utils/XTask";

let _this;
let timeout;
export default {
  name: 'WarningPopComponent',
  data() {
    return {
      TAG: 'WarningPopComponent',
      show_pop: false,
      user_token: '',
      socket_url: 'wss://consoleapi.genkigo.net/fireSafety/',
      fire_info: {
        ChargeStation: {
          "CSId": "",
          "name": "",
          "stationNumber": '',
          "province": "",
          "city": "",
          "district": "",
          "street": "",
          "communities": "",
          "roads": "",
          "address": "",
          "lon": 0,
          "lat": 0
        },
        device: {
          "deviceNumber": "",
          "deviceName": ""
        },
        "fire": 0,
        "temperature": 0,
        "screenshot": ""
      },
    }
  },
  created() {
    _this = this;
    _this.user_token = cache.get("token");
    _this.initWebsocket();
  },
  methods: {
    initWebsocket(reconnectAttempts = 0) {
      if (typeof (WebSocket) === "undefined") {
        alert("您的浏览器不支持socket")
        return;
      }

      const maxReconnectAttempts = 1000;  // 最多重连次数
      const baseDelay = 3000;  // 基础延迟时间
      const maxDelay = 60000;  // 最大延迟时间

      let url = `${_this.socket_url}${_this.user_token}`;
      // console.log(`[${this.TAG}] - websocket - ${url}`);
      _this.socket = new WebSocket(url)
      // 监听链接成功
      _this.socket.onopen = () => {
        console.log(`[${this.TAG}] me <==> success`);
        reconnectAttempts = 0;  // 重置重连尝试次数
        setInterval(() => {
          _this.sendPing();
        }, 5 * 60 * 1000);
      }
      // 监听错误
      _this.socket.onerror = (e) => {
        console.error(`[${this.TAG}] me <=/?/=> 连接错误 - `, e);
      };
      // 监听socket消息
      _this.socket.onmessage = (message) => {
        console.log(`[${this.TAG}] me <== ${message.data}`);
        try {
          const data = JSON.parse(message.data);
          if (data.Method && this[data.Method]) {
            this[data.Method](data.Body);
          } else {
            console.error(`[${this.TAG}] 未知函数 %s `, data.Method);
          }
        } catch (error) {
          console.error(`[${this.TAG}] 消息解析失败`, error);
        }
      }
      // 监听连接关闭，断线自动重新连接
      this.socket.onclose = (e) => {
        console.log(`[${this.TAG}] me <=/?/=> 链接断开...`, e);
        if (reconnectAttempts < maxReconnectAttempts) {
          let delay = Math.min(maxDelay, baseDelay * (2 ** reconnectAttempts));  // 计算阶梯延迟时间
          setTimeout(() => {
            console.log(`尝试第 ${reconnectAttempts + 1} 次重连...`);
            this.initWebsocket(reconnectAttempts + 1);
          }, delay);
        } else {
          alert("WebSocket连接失败，请检查网络或联系管理员！");
        }
      };
    },
    // WebSocket心跳包数据
    sendPing: function () {
      // console.log(`[${this.TAG}] me == PING ==>`);
      this.socket.send("PING");
    },
    // 发生火警警报
    onFireAlarm(r) {
      _this.show_pop = true;
      _this.fire_info = r.data;
    },
    // 火警警报结束
    onFireAlarmOver(r) {
      _this.closePop();
    },

    //关闭
    closePop() {
      _this.show_pop = false;
      _this.fire_info = {
        ChargeStation: {
          "CSId": "",
          "name": "",
          "stationNumber": '',
          "province": "",
          "city": "",
          "district": "",
          "street": "",
          "communities": "",
          "roads": "",
          "address": "",
          "lon": 0,
          "lat": 0
        },
        device: {
          "deviceNumber": "",
          "deviceName": ""
        },
        "fire": 0,
        "temperature": 0,
        "screenshot": ""
      }
    }
  },
}

</script>


<style>
.warning-pop-container {
  position: absolute;
  z-index: 999;
  width: 60%;
  top: 0;
  right: 0;
  left: 0;
  margin: 12% auto 0;
  background-color: #410000;
  opacity: 0.9;
  padding: 8px;
}

.warning-content-container {
  box-sizing: border-box;
  padding: 1%;
  color: #E40000;
}

.warning-pop-container .striped-border {
  position: relative;
  width: 100%;
  height: 24px;
  background: linear-gradient(-45deg, #E40000 25%, transparent 0, transparent 50%, #E40000 0, #E40000 75%, transparent 0);
  background-size: 24px 24px;
  white-space: nowrap; /* 防止内容换行 */
  animation: scroll 10s linear infinite; /* 动画名称，持续时间，线性速度，无限循环 */
  z-index: 9;
}

.warning-pop-container .striped-border .close-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  border: 2px solid #E40000;
  box-sizing: border-box;
  cursor: pointer;
  color: #E40000;
  font-size: 24px;
  line-height: 16px;
  text-align: center;
  font-weight: 700;
  z-index: 9999;
  background-color: #410000;
}

.warning-pop-container .warn-text-container {
  position: relative;
  padding: 20px;
  text-align: center;
  border: 8px solid #E40000;
}

.warning-pop-container .warn-text-container::before {
  position: absolute;
  content: "\25B6";
  left: -4px;
  bottom: 24px;
  font-size: 24px;
  color:#E40000;
  //width: 20px; //height: 20px; color: #E40000; //background-color: #fff;

}

.warning-pop-container .warn-text-container::after {
  position: absolute;
  content: "\25C0";
  right: -4px;
  bottom: 24px;
  font-size: 24px;
  color:#E40000;
  //width: 20px; //height: 20px; color: #E40000;
}

.warning-pop-container .device-temperature {
  font-size: 70px;

}

.warning-pop-container .warn-text-container .warn-text {
  font-size: 40px;
}

.warning-pop-container .warn-text-container .warn-text-tips {
  font-size: 28px;
}

.warning-pop-container .warning-cctv-pic {
  position: relative;
  width: 252px;
  height: 152px;
  border: 1px solid #fff;
  display: block;
  margin: 0 auto 16px;
  background-color: #410000; /* 子级不透明背景色 */
}

@keyframes scroll {
  from {
    background-position: 0 0; /* 起始位置 */
  }
  to {
    background-position: -100% 0; /* 终止位置 */
  }
}


</style>