<template>
  <dv-scroll-board v-if="charge_area_data" :config="charge_area_data" style="height:28vh;"/>
</template>

<script>
let _this;
export default {
  name: "ChargeAreaComponent",
  props:{
    streetCode:{
      type:String,
      default:'',
    }
  },
  data(){
    return {
      charge_area_data:{},
    }
  },
  watch:{
    streetCode:{
      handler(new_value,old_value) {
        _this.getChargeAreaList();
      }
    }
  },
  created() {
    _this=this;
  },
  methods:{
    getChargeAreaList() {
      let param = {
        street_code: _this.streetCode,
        // page:1,
        // limit:200,
        // _field:'*',
        // _orderby:'create_time desc'
      }
      if(param.street_code==''){
        return;
      }
      _this.$get('/megadata/street/communities/lst', param).then(res => {
        //   console.log(res);
        if (res.code == 0) {
          let logs = [];
          let data = res.data;
          data.forEach(item => {
            let arr = [];
            arr.push(item.communities);
            arr.push(item.csCount);
            arr.push(item.deviceSocketCount);
            arr.push((item.chargeTimeUseRate * 100).toFixed(2) + '%');
            arr.push(item.csBuildingCount);
            logs.push(arr);
          });
          _this.charge_area_data = {
            header: ["居委", "充电桩", "充电位", "使用率", "在建"],
            columnWidth: ['130'],
            data: logs,
            headerHeight: 35,
            rowNum: 4,
            align: ['center', 'center', 'center', 'center', 'center']
          }
        }
      }).catch(err => {
      });
    },
  }
}
</script>

<style scoped>

</style>