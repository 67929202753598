export class XTask {
    constructor(TAG = ""
        , taskListener
        , runInterval = 500
        , maxRunCount = 10
        , showLog = true) {
        this.TAG = TAG || "XTask";
        this.taskListener = taskListener;
        this.runInterval = runInterval;
        this.maxRunCount = maxRunCount;
        this.isRunning = false;
        this.runCount = 0;
        this.errorCount = 0;
        this.showLog = showLog;
    }

    start(delay = 0) {
        if (!this.taskListener) {
            if (this.showLog) console.log(`${this.TAG} - Task 任务停止，监控回调为null`);
            return;
        }

        this.isRunning = true;
        setTimeout(this.run.bind(this), delay);
    }

    keep() {
        setTimeout(this.run.bind(this), this.runInterval);
    }

    stop(stopCode, stopReason) {
        if (this.showLog) {
            console.log(`${this.TAG} - Task 任务已停止`);
            console.log(`==== ${this.TAG} 任务报告 ====`);
            console.log(`运行 ${this.runCount} 次`);
            console.log(`发生错误 ${this.errorCount} 次`);
            console.log(`最大运行次数：${this.maxRunCount}`);
            console.log(`停止原因：${stopCode} ${stopReason}`);
            console.log(`======================== END 任务报告 ===============================`);
        }
        if (this.isRunning) {
            this.isRunning = false;
            this.taskListener.onStop(stopCode, stopReason);
        }
    }

    destroy() {
        this.taskListener = null;
        this.maxRunCount = 0;
        this.runCount = 0;
        this.errorCount = 0;
        this.runInterval = 0;
        this.isRunning = false;
    }

    error(msg, delay = 0) {
        this.errorCount++;
        if(this.showLog) console.log(`${this.TAG} - Task 监控期间发生一个错误：${msg}`);

        setTimeout(() => {
            this.taskListener.onError(this);
        }, delay);
    }

    run() {
        this.runCount++;
        if(this.showLog) console.log(`${this.TAG} - Task 运行次数：${this.runCount}`);
        if (this.runCount > this.maxRunCount && this.maxRunCount !== -1) {
            if(this.showLog) console.log(`${this.TAG} - Task 任务停止，达到最大监控次数:${this.maxRunCount}`);
            this.stop(100, "达到最大监控次数");
            return;
        }
        this.taskListener.onRun(this);
    }
}
