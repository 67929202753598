<template>
  <div class="flex-vertical-center" style="position:absolute;width: 100%; height: 100%;z-index: 99">
    <div class="margin-center" id="map" style="width: 97%;height: 96%"></div>
  </div>
</template>
<style src="../assets/css/Base.css"></style>
<style src="../assets/css/BaseMedia960.css"></style>
<style src="../assets/css/animate.css"></style>
<style src="../assets/css/Map.css"></style>

<script>
import cache from "@/utils/cache";

let _this;
import TMap from "@/utils/TxMap";

export default {
  name: "MapComponent",
  props: {
    csList: {
      type: Array,
      default: [],
    },
    //中心点经纬度
    centerPoint: {
      type: Object,
      default: () => ({lon: 0, lat: 0}),
    },
  },
  data() {
    return {
      tMap: null, // 腾讯地图实例
      TXMap: null, // 腾讯地图原型
      markersData: [], // 标记数据
      infoWin: null, // 信息窗口实例
    }
  },
  created() {
    _this = this;
  },
  mounted() {
  },
  watch: {
    centerPoint: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (newVal === null) return;
        if (newVal.lon !== oldVal.lon || newVal.lat !== oldVal.lat) {
          if (this.tMap) {
            this.tMap.destroy();
          }
          this.initMap();
        }
      }
    },
  },
  methods: {
    initMap() {
      console.log(`街道中心经纬度 - ${this.centerPoint.lon},${this.centerPoint.lat}`)
      TMap.init().then((TMap) => {
        _this.TXMap = TMap;
        _this.tMap = new TMap.Map("map", {
          center: new TMap.LatLng(this.centerPoint.lat, this.centerPoint.lon), //设置地图中心点坐标
          zoom: 16, //设置地图缩放级别
          viewMode: "2D",
          baseMap: {			//底图设置（参数为：VectorBaseMap对象）
            type: 'vector',	//类型：失量底图
            features: ['base', 'building2d', 'label', 'point']
            //仅渲染：道路及底面(base) + 2d建筑物(building2d)，以达到隐藏文字的效果
          }
        });
        _this.tMap.on('click', _this.clickHandler);
        _this.handleMarker();
      });
    },
    clickHandler(evt) { //地址点击事件
      const lat = evt.latLng.getLat().toFixed(6);
      const lng = evt.latLng.getLng().toFixed(6);
    },
    handleMarker() {
      // let cs_list = _this.csList;
      const cs_list = cache.get("ScreenData:ChargeData");
      if (!cs_list) {
        setTimeout(function () {
          _this.handleMarker();
        }, 1000);
        return;
      }

      let myMarkers = [];
      cs_list.forEach((item, index) => {
        index = index + 1;
        let obj = {
          position: new _this.TXMap.LatLng(item.lat * 1, item.lon * 1),
          id: index,
          styleId: "marker" + index,
          properties: {
            id: index + 1,
            cs_id: `${item.CSId}`,
            title: `${item.name}`,
            name: `${item.name}`,
            status: item.status,
            province: item.province,
            city: item.city,
            district: item.district,
            address: item.street + item.communities,
            main_image: '',
            rating: item.rating,
            chargeTimeUseRate: item.chargeTimeUseRate,
            totalSocket: item.totalSocket,
            deviceSocketUsingCount: item.deviceSocketUsingCount,
            deviceSocketIdleCount: item.deviceSocketIdleCount,
            deviceSocketOccupiedCount: item.deviceSocketOccupiedCount,
            deviceSocketErrorCount: item.deviceSocketErrorCount,
            monitorTotalCount: item.monitorTotalCount,
            monitorOnlineCount: item.monitorOnlineCount,
          },
        };
        myMarkers.push(obj);
      })
      _this.markersData = myMarkers;
      _this.renderMarker();
    },
    renderMarker() {
      let markerArr = _this.markersData;
      let map = this.tMap;
      _this.marker = new _this.TXMap.MultiMarker({
        id: "marker-layer",
        map: map,
        styles: this.setMarkImg(),
        geometries: markerArr,
      });
      //给标识添加点击事件
      _this.marker.on("click", (event) => {
        let {lat, lng} = event.latLng;
        let {title, address, total_socket, status, main_image, rating, id} = event.geometry.properties;
        let CSInfo = event.geometry.properties;
        _this.initWindow(CSInfo, lat, lng);
        // _this.infoWin.open();
        //_this.infoWin.open();
      });
    },
    setMarkImg() {
      let styleOption = {};
      let markerArr = _this.markersData;
      // 遍历图标集合
      markerArr.map((item, index) => {
        let image_url = '';
        if (item.properties.status == 2) {
          image_url = 'https://res.mnft.vip/public/evcharge/map/img-4.png?t_1';
        } else {
          switch (item.properties.rating) {
            case 1:
              image_url = 'https://res.mnft.vip/public/evcharge/map/img-1.png?t_1';
              break;
            case 2:
              image_url = 'https://res.mnft.vip/public/evcharge/map/img-2.png?t_1';
              break;
            case 3:
              image_url = 'https://res.mnft.vip/public/evcharge/map/img-3.png?t_1';
              break;
          }
        }
        index = index + 1;
        styleOption["marker" + index] = new this.TXMap.MarkerStyle({
          cursor: "pointer",
          width: 40,
          height: 40,
          anchor: {x: 16, y: 32},
          src: image_url,
        });
      });
      return styleOption;
    },
    // 初始化一个信息窗口
    initWindow(CSInfo, lat, lng) {
      // 一次只能打开一个窗口 打开之前先关闭之前打开的
      if (this.infoWin) {
        _this.closeWindow();
      }
      // let CSInfo = event.geometry.properties;
      let content = ``;
      content += `<div class="equipment map-container">`;
      content += `<div class="map-container-header flex-between"> <div class="ml3p">充电桩详情</div> <i  id="closeIcon_${CSInfo.id}" class="el-icon-close font-16 mr3p" @click="closeWindow()"></i>  </div>`
      content += `<div class="containers94 margin-center"> <p>电桩：<span>${CSInfo.title}</span></p>`;
      content += `<p>地址：<span>${CSInfo.province} ${CSInfo.city} ${CSInfo.district} ${CSInfo.address}</span></p>`;
      // content += `<p>地址：<span>${CSInfo.address}</span></p>`;

      if (CSInfo.status === 1) {
        content += ` <p>状态：<span style="color: #1BFFC5;">运营中</span></p>`;
      }
      if (CSInfo.status === 2) {
        content += ` <p>状态：<span style="color: #888787;">在建</span></p>`;
      }
      content += `<p>电位：<span style="color: #FF7575;">总 ${CSInfo.totalSocket}</span>&nbsp;/&nbsp;<span style="color: #f8064f;">异常 ${CSInfo.deviceSocketErrorCount}</span></p>`;

      switch (CSInfo.rating) {
        case 1: //使用率优秀
          content += `<p>使用率：<span style="color: #FF7575;">${(CSInfo.chargeTimeUseRate * 100).toFixed(2)}%</span></p>`;
          break
        case 2: //使用率适中
          content += `<p>使用率：<span style="color: #3292fa;">${(CSInfo.chargeTimeUseRate * 100).toFixed(2)}%</span></p>`;
          break
        case 3: //使用率低
          content += `<p>使用率：<span style="color: #f8064f;">${(CSInfo.chargeTimeUseRate * 100).toFixed(2)}%</span></p>`;
          break
      }
      content += ` <div class="mb14 view-all-btn " id="cctvBtn_${CSInfo.id}"  @click="showCCTV(${CSInfo})">查看监控</div> `;
      content += `<div/></div>`
      _this.infoWin = new this.TXMap.InfoWindow({
        map: _this.tMap,
        position: new this.TXMap.LatLng(lat, lng),
        // enableCustom: true,
        content: content,
        offset: {x: 0, y: -20}, //设置信息窗相对position偏移像素
      });
      _this.infoWin.open();
      setTimeout(function () {
        document.getElementById(`closeIcon_${CSInfo.id}`).addEventListener('click', function () {
          _this.closeWindow();
        });

        document.getElementById(`cctvBtn_${CSInfo.id}`).addEventListener('click', function () {
          _this.showCCTV(CSInfo);
        });
      }, 100);
      // 信息窗口关闭回调
      _this.infoWin.on("closeclick", () => {
        //  _this.closeWindow();
        // ...
      });
    },
    // 关闭信息窗口
    closeWindow() {
      if (_this.infoWin) {
        _this.infoWin.close();
      }
    },
    showCCTV(station_info) {
      _this.$emit('getStationInfo', station_info);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', _this.updateHeight);
    _this.tMap.destroy();
  }
}
</script>

<style scoped>
.header-right-icon {
  color: #ffffff;
}

.qi-502-fill, .qi-504-fill {
  color: rgba(255, 255, 255, 0.65) !important;
}

.qi-100-fill, .qi-1014 {
  color: #febc16 !important;
}

.qi-900-fill {
  color: red !important;
}

.qi-101-fill, .qi-102-fill, .qi-103-fill, .qi-503-fill, .qi-507-fill, .qi-508-fill {
  color: #fe983f;
}

.qi-300-fill, .qi-301-fill, .qi-406-fill, .qi-407-fill {
  color: #7569ef;
}

.qi-302-fill, .qi-303-fill, .qi-304-fill, .qi-313-fill, .qi-399-fill, .qi-408-fill, .qi-409-fill, .qi-410-fill, .qi-499-fill, .qi-901-fill {
  color: #3292fa;
}

.qi-305-fill, .qi-306-fill, .qi-307-fill, .qi-308-fill, .qi-309-fill, .qi-310-fill, .qi-311-fill, .qi-312-fill, .qi-314-fill, .qi-315-fill, .qi-316-fill {
  color: #9b9efc;
}

.qi-399-fill .qi-400-fill .qi-401-fill .qi-402-fill .qi-403-fill .qi-404-fill .qi-405-fill {
  color: #6569fd;
}

.qi-500-fill .qi-501-fill {
  color: rgba(89, 255, 92, 0.58);
}
</style>