<template>
    <div>
      <div id="BikeCountChart" class="margin-center mt16 mb16" style="width: 25vw;"
           :style="{height:!isFullScreen?'25vh':'27vh'}"></div>
      <div class=" containers80 margin-center flex-around"
           style="position: absolute;left: 0;right: 0;bottom: 16px;">
        <div class="common-btn containers30" :class="select_charge_days==7?'select-btn':''"
        >7天
        </div>
        <div class="common-btn containers30" :class="select_charge_days==30?'select-btn':''"
        >30天
        </div>
        <div class="common-btn containers30" :class="select_charge_days==90?'select-btn':''"
        >90天
        </div>
      </div>
    </div>
</template>

<script>
import VueCountUp from 'vue-countupjs'
import echarts from "@/views/Screen/assets/js/echarts.ts";

let _this;
let interval = null;
let interval_index = 0;
export default {
  name: "BikeCountChartComponent",
  components: {
    VueCountUp
  },
  props: {
    streetCode: {
      type: String,
      default: '',
    },
    areaInfo: {
      type: Object,
      default: {},
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      swipe_time: 5000,
      select_charge_days: 7,
      charge_bike_chart: null,
      chart_grid: {
        top: '5%', // 上边距
        bottom: '12%', // 下边距
        left: '12%', // 左边距
        right: '8%', // 右边距
      }
    }
  },
  watch: {
    streetCode: {
      handler(new_value, old_value) {
        clearInterval(interval);
        interval = null;
        if (_this.charge_bike_chart!=null) {
          _this.charge_bike_chart.dispose();
          _this.charge_bike_chart = null;
        }
        _this.getBikeChartData();
        _this.swipeChartData();
      }
    },
    select_charge_days:{
      handler(new_value, old_value) {
        _this.getBikeChartData();
      }
    }
  },
  created() {
    _this = this;
  },
  mounted() {},
  methods: {
    bubbleSortByDate(arr) {
      let len = arr.length;
      for (let i = 0; i < len - 1; i++) {
        for (let j = 0; j < len - 1 - i; j++) {
          // 将日期字符串转换为日期对象以比较
          let date1 = new Date(arr[j].date);
          let date2 = new Date(arr[j + 1].date);
          if (date1 > date2) {
            // 交换两个元素的位置
            let temp = arr[j];
            arr[j] = arr[j + 1];
            arr[j + 1] = temp;
          }
        }
      }
      return arr;
    },
    getBikeChartData() {
      let param = {};
      param.street_code = _this.streetCode;
      param.days = _this.select_charge_days;
      let cache_data = _this.$cache.get('ChargeChartData' + param.street_code + param.days);
      if (param.street_code == '') {
        return;
      }
      if (cache_data) {
        if (_this.charge_count_chart != null) {
          _this.setBikeCountChart(cache_data);
        } else {
          _this.initBikeCountChart(cache_data);
        }
        return;
      }
      _this.$get("/megadata/street/chart", param).then(res => {
        if (res.code == 0) {
          const _data=_this.bubbleSortByDate(res.data);
          _this.$cache.set('BikeChartData' + param.street_code + param.days, _data);
          _this.initBikeCountChart(_data);
        }
      }).catch(err => {
      })
    },
    setBikeCountChart(data) {
      // background: linear-gradient(180deg, #1BFFC5 0%, #3EB9FF 100%);
      const option = {
        xAxis: {
          data: data.map(item => {
            return item.date
          }),

        },
        series: [{
          name: "累计充电车辆数量",
          type: 'line',
          data: data.map(item => {
            return item.chargingUserCount
          }),
        },

        ]
      };
      _this.charge_bike_chart.setOption(option);
    },
    initBikeCountChart(data) {
      if(_this.charge_count_chart!=null){
        _this.setChargeCountChart(data);
        return;
      }
      _this.charge_bike_chart = echarts.init(document.getElementById('BikeCountChart'));
      const gradientColor = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {offset: 0, color: 'rgba(27, 255, 197,0)'},
        {offset: 1, color: 'rgba(62, 185, 255, 1)'},
      ]);
      // background: linear-gradient(180deg, #1BFFC5 0%, #3EB9FF 100%);
      const option = {
        grid: _this.chart_grid,
        color: ["#FFE071"],
        textStyle: {
          color: "#3EB9FF",
          fontFamily: "sans-serif",
          fontSize: "12",
          fontStyle: "normal",
          fontWeight: "normal"
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          data: data.map(item => {
            return item.date
          }),

        },
        yAxis: {
          title: {},
          splitLine: {
            show: true,  // 显示纵轴辅助线
            lineStyle: {
              color: '#3EB9FF',  // 辅助线颜色
            },
          },
        },
        series: [{
          name: "累计充电车辆数量",
          type: 'line',
          data: data.map(item => {
            return item.chargingUserCount
          }),
          showSymbol: false, // 取消锚点显示
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(210,185,96,0.8)'
              },
              {
                offset: 1,
                color: 'rgba(210,185,96,0.3)'
              }
            ])
          },
        },

        ]
      };
      _this.charge_bike_chart.setOption(option);
    },
    swipeChartData() {
      interval = setInterval(function () {
        let arr = [7, 30, 90];
        if (interval_index == arr.length) {
          interval_index = 0;
        }
        _this.select_charge_days = arr[interval_index];
        interval_index++;
      }, _this.swipe_time);
    }
  },
  beforeDestroy(){
    _this.charge_bike_chart.dispose();
  }

}
</script>

<style scoped>

</style>