<template>
  <dv-scroll-board :config="charge_logs" style="height: calc(24vh - 110px);"/>
</template>

<script>
let _this;
export default {
  name: "ChargeLogsComponent",
  props:{
    streetCode:{
      type:String,
      default:'',
    }
  },
  data(){
    return {
      charge_logs:{},
    }
  },
  watch:{
    streetCode:{
      handler(new_value,old_value) {
        _this.getChargeLogs();
      }
    }
  },
  created() {
    _this=this;
  },
  methods:{
    getChargeLogs() {
      let param = {
        street_code: _this.streetCode,
      }
      _this.$get('/megadata/street/logs/lst', param).then(res => {
        if (res.code == 0) {
          let logs = [];
          let data = res.data;
          data.forEach(item => {
            let arr = [];
            switch (item.log_type) {
              case 101:
                arr.push(`<span>${item.content}</span><span class="ml4" style="color: #3EFFFF">已巡检</span>`);
                break;
              default:
                arr.push(`<span>${item.content}</span><span class="ml4" style="color: #3EFFFF">已巡检</span>`);
            }
            arr.push(_this.$utils.formatDate(item.create_time, "m-d H:i"));
            logs.push(arr);

          });
          _this.charge_logs = {
            align: ['left', 'right'],
            columnWidth: ['300'],
            data: logs,
            rowNum: 3,
            waitTime: 3600 * 1000
          }
        } else {
          _this.charge_logs = {
            align: ['left', 'right'],
            data: [],
            rowNum: 3,
            waitTime: 3600 * 1000
          }
        }
      }).catch(err => {
      });
    },
  }
}
</script>

<style scoped>

</style>