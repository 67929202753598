<template>
  <div class="screen-container">
    <div class="screen-container-header flex-between">
      <div class="header-left flex-first">

        <img src="./assets/images/icon-1.png" class="icon" alt=""
             @click="show_street_visible=!show_street_visible">

<!--        <img src="./assets/images/icon-1.png" class="icon" alt="" v-if="show_street_visible"-->
<!--             @click="show_street_visible=false">-->
<!--        -->
        <span class="font-white font-family-base font-20 ml16 cursor-pointer"
              @click="show_street_visible=!show_street_visible">{{ area_info.city }}-{{ area_info.district }}-{{
            area_info.street
          }}</span>
<!--        <span class="font-white font-family-base font-20 ml16 cursor-pointer" v-if="!show_street_visible"-->
<!--              @click="show_street_visible=true">{{ area_info.city }}-{{ area_info.district }}-{{-->
<!--            area_info.street-->
<!--          }}</span>-->
        <div class="el-icon-caret-bottom cursor-pointer"  @click="show_street_visible=!show_street_visible" ></div>
        <div class="street-list" v-if="show_street_visible" style="max-height: 500px;overflow-y: scroll">
          <div class="street-panel containers96 margin-center flex-between" @click="selectStreet(item.street_code)"
               v-for="item in street_list">
            <div class="ml3p">{{ item.street_name }}</div>
            <div class="mr3p el-icon-circle-check flex-first" v-if="item.street_code==street_code">
              <img src="@/assets/images/icon-1.png"  alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="header-center text-center flex-center font-white font-28 font-weight-700">
        电动自行车充电站 AI智慧安全监管平台
      </div>
      <div class="header-right font-white font-16 flex-end">
        <div class="font-16 font-weight-700" v-if="user_info.remark!=''">{{ user_info.remark }}</div>
        <div class="ml16">{{ current_date }}</div>
        <div class="ml8 flex-first">
          <!--          <img src="./assets/images/icon-3.png" class="header-right-icon" alt="">-->
          <i v-if="weather_info.icon < 1000" :class="['header-right-icon','qi-' + weather_info.icon +'-fill']"></i>
          <i v-if="weather_info.icon >= 1000" :class="['header-right-icon','qi-' + weather_info.icon]"></i>
          <span class="ml4">{{ weather_info.text }}</span>
        </div>
        <div class="ml16 flex-first cursor-pointer" @click="toggleFullScreen">
          <img src="./assets/images/icon-5.png" class="header-right-icon" alt="">
        </div>

      </div>
    </div>
    <div class="content flex-between">
      <div class="content-left">
        <dv-border-box-12 class="margin-center content-left-charge-data">
          <div class="common-title">{{ area_info.street }} 充电桩数据</div>
          <div class="flex-first containers94 margin-center">
            <!--            <div id="UseRate" style="width: 16vh;height: 16vh;" :style="{width:window_height<1080?'16vh':'20vh',height:window_height<1080?'16vh':'20vh'}"></div>-->
            <dv-decoration-9
                :class="{'content-left-use-rate':!is_full_screen,'content-left-full-screen-use-rate':is_full_screen}">
              <div class="font-28 font-white"
                   style="color: rgb(0, 186, 255);text-shadow: 0px 2px 4px rgb(0, 186, 255);">
                {{ (area_info.chargeTimeUseRate * 100).toFixed(2) }}
              </div>
            </dv-decoration-9>
            <div class="font-white ml16">
              <div style="color: #FFB016;" class="font-40">{{ (area_info.chargeTimeUseRate * 100).toFixed(2) }}<span
                  class="font-24">%</span></div>
              <div class="font-20 mt8">整体使用率</div>
            </div>
          </div>
          <div class="containers96 margin-center flex-around text-center mt16">
            <div class="font-white font-family-base containers30">
              <div class="font-32 ">
                <VueCountUp :start-value="0" :end-value="area_info.csCount" :duration="1"/>
              </div>
              <div class="font-16">总充电桩数</div>
            </div>
            <div class="font-white font-family-base containers30">
              <!--              <div class="font-32 ">{{ area_info.deviceSocketCount }}</div>-->
              <div class="font-32 ">
                <VueCountUp :start-value="0" :end-value="area_info.deviceSocketCount" :duration="1"/>
              </div>
              <div class="font-16">总充电位</div>
            </div>
            <div class="font-white font-family-base containers30">
              <!--              <div class="font-32" style="color: #1BFFC5;">{{ area_info.deviceSocketUsingCount }}</div>-->
              <div class="font-32" style="color: #1BFFC5;">
                <VueCountUp :start-value="0" :end-value="area_info.deviceSocketUsingCount" :duration="1"/>
              </div>
              <div class="font-16">充电位使用中</div>
            </div>
          </div>
          <div class="data-detail containers94 margin-center flex-around font-14 font-white mt16 ">
            <div>空闲 {{ area_info.deviceSocketIdleCount }}</div>
            <div style="color: #1BFFC5;">使用中 {{ area_info.deviceSocketUsingCount }}</div>
            <div style="color: #FFE071;">占用中 {{ area_info.deviceSocketOccupiedCount }}</div>
            <div style="color: #FF3C3C;">检修中 {{ area_info.deviceSocketErrorCount }}</div>
          </div>
        </dv-border-box-12>
        <dv-border-box-12 class="margin-center charge-area-list" style="width: 98%;">
          <div class="common-title">社区</div>
          <!--          <div class="common-dot containers94 margin-center"></div>-->
          <div class="containers94 margin-center">
            <!--            <dv-scroll-board :config="charge_area_data" style="height:28vh;"/>-->
            <ChargeAreaComponent :street-code="street_code"></ChargeAreaComponent>
          </div>
        </dv-border-box-12>
        <dv-border-box-12 class="margin-center content-left-logs" style="width: 98%;">
          <div class="common-title">最新日志</div>
          <div class="containers94 margin-center">
            <ChargeLogsComponent :street-code="street_code"></ChargeLogsComponent>
          </div>
        </dv-border-box-12>
      </div>
      <div class="content-center">
        <dv-border-box-12 class="content-center-map">
          <MapComponent :cs-list="cs_list"
                        :center-point="area_centerPoint"
                        @getStationInfo="handleStationInfo"></MapComponent>
          <div class="tips-1">数幻科技·元气充技术支持</div>
        </dv-border-box-12>
<!--        <el-carousel height="6vh" direction="vertical" indicator-position="none" :autoplay="true">-->
          <div v-for="(item,index) in weather_list" :key="index">
            <dv-border-box-12 class="content-center-message"
                              :class="item.log_type==10?'danger':'warning'"
                              :color="item.log_type==10?['#FF4F4F','#FF4F4F']:['#FFE071','#FFE071']"
                              :backgroundColor="item.log_type==10?'#380A0A':'#382B0A'">
              <div class="flex-first ml2p" style="height: 6vh;">
                <i class="el-icon-warning font-20"></i>
                <div class="ml8 font-16">{{ item.content }}</div>
              </div>
              <!--                  <div class="flex-first ml2p" style="height: 6vh;">-->
              <!--                    <i class="el-icon-warning font-20" ></i>-->
              <!--                    <div class="ml8 font-16">{{weather_info.content}}</div>-->
              <!--                  </div>-->
            </dv-border-box-12>
          </div>
<!--        </el-carousel>-->
        <div class="margin-center flex-between">
          <dv-border-box-12 class="content-center-logs">
            <div class="common-title">监控数据</div>
            <div class="font-white font-14" style="overflow: hidden;">
              <div class="flex-first containers94 margin-center flex-first">
                <img src="./assets/images/icon-10.png" class="common-icon-20" alt="">
                <div class="ml8 ">区域：{{ area_info.city }}-{{ area_info.district }}-{{ area_info.street }}</div>
              </div>
              <div class="flex-first containers94 margin-center flex-first mt8">
                <img src="./assets/images/icon-11.png" class="common-icon-20" alt="">
                <div class="ml8">数量：{{ area_info.monitorTotalCount }}（在线{{ area_info.monitorOnlineCount }}
                  离线{{ area_info.monitorTotalCount - area_info.monitorOnlineCount }}）
                </div>
              </div>
            </div>
            <div class="view-all-btn margin-center containers80 mt16"
                 @click="$funcs.jumpUrl('/cctv?street='+street_code)">
              查看全部
            </div>
            <div class="charge-station"></div>
          </dv-border-box-12>
          <dv-border-box-12 class=" content-center-cctv">
            <div class="common-title">使用率过低充电桩</div>
            <div class="containers94 margin-center" style="height: 24vh;">
              <dv-scroll-board :config="use_less_cs_list" style="height: 24vh;"/>
            </div>
          </dv-border-box-12>
        </div>
      </div>
      <div class="content-right">
        <dv-border-box-12 class="content-right-charge-count">
          <div class="common-title">充电数</div>
          <ChargeCountChartComponent :street-code="street_code"
                                     :area-info="area_info"
                                     :is-full-screen="is_full_screen"
          ></ChargeCountChartComponent>
        </dv-border-box-12>
        <dv-border-box-12 class="content-right-bike-count">
          <div class="common-title">累计新增车辆数
            <span class="mt16 font-white"><VueCountUp :start-value="0" :end-value="area_info.chargingUserCount"
                                                      :duration="1.5"/></span>
          </div>
          <BikeCountChartComponent :street-code="street_code"
                                   :area-info="area_info"
                                   :is-full-screen="is_full_screen"
          ></BikeCountChartComponent>
        </dv-border-box-12>
        <dv-border-box-12 class="content-right-use-less grid-center">
          <div class="grid-center font-14">
            <div class="flex-center">
              <img src="./assets/images/icon-14.png" class="common-icon-16 " alt="">
              <div class="ml8 font-14">2024数幻科技元气充AI.2.0智慧安全管理平台</div>
            </div>
            <div class="flex-center">
              <img src="./assets/images/icon-15.png" class="common-icon-16 " alt="">
              <div class="ml8">链式反应科技AI技术支持</div>
            </div>
          </div>
        </dv-border-box-12>
      </div>
    </div>
    <WarningPopComponent></WarningPopComponent>
    <div class="camera-item  flex-center" v-if="show_cctv" :style="{width: cctv_width+'px', height: cctv_height+'px'}">
      <YSLivePlayer
          v-if="station_cctv_info.config.LiveProtocol==='ys'"
          :SerialNumber="station_cctv_info.serialNumber"
          :AccessToken="station_cctv_info.YSAccessToken"
          :width="cctv_width"
          :height="cctv_height"/>
      <WebRTCVideoV2
          v-else-if="station_cctv_info.config.LiveProtocol === 'WebRTC-WAPA'"
          :title="station_cctv_info.serialNumber +' '+station_cctv_info.name"
          :signal-request="webRTCSignalRequestWithWAPA"
          :data="{...station_cctv_info}"
          :width="cctv_width"
          :height="cctv_height"></WebRTCVideoV2>
      <div class="close-icon" @click="closeCCTV"></div>
    </div>
  </div>
</template>
<style src="./assets/css/Base.css"></style>
<style src="./assets/css/BaseMedia960.css"></style>
<style src="./assets/css/animate.css"></style>
<style src="./assets/css/Map.css"></style>
<script>
import VueCountUp from 'vue-countupjs'
import ChargeAreaComponent from "@/views/Screen/components/ChargeAreaComponent.vue";
import ChargeLogsComponent from "@/views/Screen/components/ChargeLogsComponent.vue";
import ChargeCountChartComponent from "@/views/Screen/components/ChargeCountChartComponent.vue";
import BikeCountChartComponent from "@/views/Screen/components/BikeCountChartComponent.vue";
import MapComponent from "@/views/Screen/components/MapComponent.vue";
import cache from "@/utils/cache";
import WarningPopComponent from "@/views/Screen/components/WarningPopComponent.vue";
import YSLivePlayer from "./components/YSLivePlayer.vue";
import WebRTCVideoV2 from "@/views/Screen/components/WebRTCVideoV2.vue";
import HttpUtil, {ContentType} from "@/utils/HttpUtil";
import {XTask} from "@/utils/XTask";

let _this;
let interval = null;
let interval_index = 0;
export default {
  name: "Street",
  components: {
    WebRTCVideoV2,
    WarningPopComponent,
    MapComponent,
    BikeCountChartComponent,
    ChargeCountChartComponent,
    ChargeLogsComponent,
    ChargeAreaComponent,
    VueCountUp,
    YSLivePlayer
  },
  data() {
    return {
      user_info: {
        remark: '',
      },
      window_height: window.innerHeight,
      markersData: [], // 标记数据
      infoWin: null, // 信息窗口实例
      street_code: '',
      current_date: '',
      cs_list: [],
      is_full_screen: false,
      charge_area_data: {
        header: ["居委", "充电桩", "充电位", "使用率", "在建"],
        columnWidth: ['150'],
        data: [],
        headerHeight: 35,
        rowNum: 3,
        align: ['center', 'center', 'center', 'center', 'center']
      },
      charge_area_list: [],
      area_info: {},
      area_list: [],
      area_centerPoint: {},
      charge_logs: {
        align: ['left', 'right'],
        data: [],
        rowNum: 3,
        waitTime: 3600 * 1000
      },
      use_less_cs_list: {
        align: ['left', 'right'],
        data: [],
        rowNum: 4,
        waitTime: 7200 * 1000
      },
      charge_count_data: [],
      ebike_count_data: [],
      charge_uss_rate_chart: null,
      street_list: [],
      current_street_info: {},
      show_street_visible: false,
      swipe_time: 5000,
      weather_list: [],
      weather_info: {},
      chart_grid: {
        top: '5%', // 上边距
        bottom: '12%', // 下边距
        left: '12%', // 左边距
        right: '8%', // 右边距
      },
      cctv_width: 0,
      cctv_height: 0,
      station_cctv_info: {},
      show_cctv: false,
      refreshTask: null,
    }
  },
  created() {
    _this = this;
    _this.getStreetList();
    _this.user_info = _this.$cache.get("user_info");
    _this.initCCTVWidth();
    setInterval(() => {
      _this.current_date = _this.$utils.formatDate(new Date().getTime(), "Y-m-d H:i:s");
    }, 1000);
    if (_this.window_height >= 880) {
      _this.chart_grid = {
        top: '5%', // 上边距
        bottom: '12%', // 下边距
        left: '12%', // 左边距
        right: '8%', // 右边距
      }
    }
    if (_this.window_height < 880 && _this.window_height >= 540) {
      _this.chart_grid = {
        top: '5%', // 上边距
        bottom: '20%', // 下边距
        left: '15%', // 左边距
        right: '8%', // 右边距
      }
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      _this.updateHeight();
    });
    _this.swipeChartData();
  },
  methods: {
    handleStationInfo(info) {
      let param = {};
      param.street_code = _this.street_code;
      param.cs_id = info.cs_id;
      _this.$get('/megadata/street/v2/monitor/get', param).then(async res => {
        if (res.code !== 0) {
          console.log(`获取监控数据失败：${res}`)
          return;
        }
        let c = res.data;
        let YSAccessToken = '';
        if (c.config) {
          c.config = JSON.parse(c.config);
          if (c.config.LiveProtocol === 'ys') {
            YSAccessToken = await _this.getYSAccessToken();
          }
        }
        _this.station_cctv_info = c;
        _this.station_cctv_info.YSAccessToken = YSAccessToken;
        _this.show_cctv = true;
      }).catch(err => {
        console.log(`获取监控数据发生错误 - ${err}`);
      });
    },
    closeCCTV() {
      _this.station_cctv_info = {};
      _this.show_cctv = false;
    },
    initCCTVWidth() {
      const window_width = window.innerWidth * 0.96;
      const cctv_width = Number((window_width * 0.49).toFixed(0));
      const cctv_height = Number(((cctv_width * 555) / 924).toFixed(0));
      _this.cctv_width = cctv_width;
      _this.cctv_height = cctv_height;
    },
    getWeatherInfo() {
      let param = {
        street_code: _this.street_code,
      }
      _this.$get('/megadata/street/weather/now', param).then(res => {
        // console.log(res);
        if (res.code === 0) {
          _this.weather_info = res.data;
        }
      }).catch(err => {

      });
    },
    getPlatformMsg() {
      let param = {
        street_code: _this.street_code,
        log_type: '10,11',
      }
      _this.$get('/megadata/street/logs/lst', param).then(res => {
        //   console.log(res);
        if (res.code === 0) {
          _this.weather_list = res.data;
        }
      }).catch(err => {
      });
    },
    getStreetList() {
      _this.$get("/megadata/street/lst", {}).then(res => {
        if (res.code === 0) {
          _this.street_list = res.data;
          _this.street_code = res.data[0].street_code;
          _this.getChargeAreaInfo(() => {
            _this.getChargeStation();
          });
          _this.getPlatformMsg();
          _this.getWeatherInfo();
          _this.startRefreshTask();
        } else {
          if (res.code === 999) {
            _this.$jayMessage({
              content: "登录信息失效",
              type: "warn",
              onClose:function () {
                //_this.$router.push('/street');
                _this.$utils.jumpUrl('/login');
              }
            }).show();
            // _this.$message.error({
            //   message: '登录信息失效',
            //   onClose: function () {
            //     _this.$utils.jumpUrl('/login');
            //   }
            // })
          }
        }
      }).catch(err => {
      });
    },
    selectStreet(street_code) {
      if (street_code === _this.street_code) {
        _this.show_street_visible = false;
        return;
      }
      clearInterval(interval);
      interval = null;

      _this.street_code = street_code;
      _this.show_street_visible = false;
      _this.getChargeAreaInfo(() => {
        _this.getChargeStation();
      });
      _this.swipeChartData();
    },
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        _this.is_full_screen = true;
        _this.openFullScreen();
      } else {
        _this.is_full_screen = false;
        _this.closeFullScreen();
      }
    },
    openFullScreen() {
      let elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen();
      }
    },
    closeFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    },
    getChargeStation() {
      let param = {
        street_code: _this.street_code
      }
      cache.delete("ScreenData:ChargeData");
      _this.$post('/megadata/street/ChargeStation/lst', param, {headers: {content_type: 'multipart/form-data'}}).then(res => {
        if (res.code === 0) {
          _this.cs_list = res.data;
          cache.set("ScreenData:ChargeData", res.data);
          let logs = [];
          let data = res.data;
          data.forEach(item => {
            if (item.rating === 3 && item.status === 1) {
              let arr = [];
              arr.push(item.name);
              arr.push(`<span style="color: #FF7575;">${(item.chargeTimeUseRate * 100).toFixed(2)}%</span>`);
              logs.push(arr);
            }
          });
          _this.use_less_cs_list = {
            align: ['left', 'right'],
            data: logs,
            rowNum: 4,
            waitTime: 3000
          }
          // _this.handleMarker();
        } else {
          if (res.code === 999) {
            _this.$jayMessage({
              content: "登录信息失效",
              type: "warn",
              onClose:function () {
                //_this.$router.push('/street');
                _this.$utils.jumpUrl('/login');
              }
            }).show();
            // _this.$message.error({
            //   message: '登录信息失效',
            //   onClose: function () {
            //     _this.$utils.jumpUrl('/login');
            //   }
            // })
          }
        }
      }).catch(err => {
        console.log(err);
      });
    },
    //初始化充电次数数据图表
    getChargeAreaInfo(_cb_) {
      _this.$get('/megadata/street/Summary', {street_code: _this.street_code}).then(res => {
        if (res.code === 0) {
          _this.area_info = res.data;
          _this.area_centerPoint = {
            lon: _this.area_info.lon, //经度
            lat: _this.area_info.lat, //维度
          }
          if (typeof _cb_ === "function") _cb_();
        }
      }).catch(err => {
      });
    },
    getChargeLogs() {
      let param = {
        street_code: _this.street_code,
      }
      _this.$get('/megadata/street/logs/lst', param).then(res => {
        //   console.log(res);
        if (res.code === 0) {
          let logs = [];
          let data = res.data;
          data.forEach(item => {
            let arr = [];
            switch (item.log_type) {
              case 101:
                arr.push(`<span>${item.content}</span><span class="ml4" style="color: #3EFFFF">已巡检</span>`);
                break;
              default:
                arr.push(`<span>${item.content}</span><span class="ml4" style="color: #3EFFFF">已巡检</span>`);
            }
            arr.push(_this.$utils.formatDate(item.create_time, "m-d H:i"));
            logs.push(arr);

          });
          _this.charge_logs = {
            align: ['left', 'right'],
            columnWidth: ['300'],
            data: logs,
            rowNum: 3,
            waitTime: 3600 * 1000
          }
        } else {
          _this.charge_logs = {
            align: ['left', 'right'],
            data: [],
            rowNum: 3,
            waitTime: 3600 * 1000
          }
        }
      }).catch(err => {
      });
    },
    updateHeight() {
      _this.window_height = window.innerHeight;
      // console.log(_this.charge_count_chart);
      if (_this.window_height >= 880) {
        _this.chart_grid = {
          top: '5%', // 上边距
          bottom: '12%', // 下边距
          left: '12%', // 左边距
          right: '8%', // 右边距
        }
      }
      if (_this.window_height < 880 && _this.window_height >= 540) {
        _this.chart_grid = {
          top: '5%', // 上边距
          bottom: '20%', // 下边距
          left: '15%', // 左边距
          right: '8%', // 右边距
        }
      }
      setTimeout(() => {
        _this.charge_count_chart.resize();
        _this.charge_bike_chart.resize();
        _this.charge_uss_rate_chart.resize();
      }, 100);
    },
    swipeChartData() {
      interval = setInterval(function () {
        let arr = [7, 30, 90];
        if (interval_index === arr.length) {
          interval_index = 0;
        }
        _this.select_charge_days = arr[interval_index];
        _this.select_bike_days = arr[interval_index];
        interval_index++;
      }, _this.swipe_time);
    },
    //获取萤石云监控授权码
    async getYSAccessToken() {
      console.log("getYSAccessToken");
      const r = await _this.$get("/sdk/ys/access_token");
      if (r.code !== 0) return "";
      return r.data.accessToken;
    },
    //WebRTC信令服务器请求
    async webRTCSignalRequestWithWAPA(offer, device) {
      // 向信令服务器发送请求
      const apiUrl = `https://${device.config.IP}:${device.config.port}/rtc/v1/play/`;
      const params = {
        "api": apiUrl,
        "channelid": device.config.channelid,
        "clientip": null,
        "nettype": device.config.nettype,
        "sdp": offer.sdp,
        "stream": device.serialNumber,
        "streamurl": `webrtc://${device.config.IP}/play/${device.serialNumber}`,
        "tid": Number(parseInt(new Date().getTime() * Math.random() * 100)).toString(16).slice(0, 7),
        "username": device.config.username,
        "password": device.config.password,
      };
      const response = await HttpUtil.POST(apiUrl, params, ContentType.JSON);
      if (response && response.code === 0) {
        return response.sdp;
      } else {
        console.error('无法从信令服务器获取 SDP');
      }
      return undefined;
    },
    startRefreshTask() {
      if (this.refreshTask !== null) {
        this.refreshTask = null;
      }
      this.refreshTask = new XTask("刷新任务", {
        onRun(xTask) {
          console.log(`首页 - 正在刷新数据...`);
          _this.getChargeAreaInfo();
          _this.getPlatformMsg();
          _this.getWeatherInfo();
          xTask.keep();
        }
      }, 30 * 60 * 1000, -1);
      this.refreshTask.start(30 * 60 * 1000);
    },
  }
  ,
  beforeDestroy() {
    window.removeEventListener('resize', _this.updateHeight);
    _this.tMap.destroy();
  }
  ,
  watch: {
    window_height(newHeight, oldHeight) {
      _this.window_height = newHeight;
      console.log("window_height=" + newHeight)
    },
  }
}
</script>
<style scoped>
.header-right-icon {
  color: #ffffff;
}

.qi-502-fill, .qi-504-fill {
  color: rgba(255, 255, 255, 0.65) !important;
}

.qi-100-fill, .qi-1014 {
  color: #febc16 !important;
}

.qi-900-fill {
  color: red !important;
}

.qi-101-fill, .qi-102-fill, .qi-103-fill, .qi-503-fill, .qi-507-fill, .qi-508-fill {
  color: #fe983f;
}

.qi-300-fill, .qi-301-fill, .qi-406-fill, .qi-407-fill {
  color: #7569ef;
}

.qi-302-fill, .qi-303-fill, .qi-304-fill, .qi-313-fill, .qi-399-fill, .qi-408-fill, .qi-409-fill, .qi-410-fill, .qi-499-fill, .qi-901-fill {
  color: #3292fa;
}

.qi-305-fill, .qi-306-fill, .qi-307-fill, .qi-308-fill, .qi-309-fill, .qi-310-fill, .qi-311-fill, .qi-312-fill, .qi-314-fill, .qi-315-fill, .qi-316-fill {
  color: #9b9efc;
}

.qi-399-fill .qi-400-fill .qi-401-fill .qi-402-fill .qi-403-fill .qi-404-fill .qi-405-fill {
  color: #6569fd;
}

.qi-500-fill .qi-501-fill {
  color: rgba(89, 255, 92, 0.58);
}

.camera-item {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 8% auto;
  z-index: 9999;
  overflow: hidden;
  width: auto;
  /*height: 300px;*/
  border-radius: 16px;
  border: .0625rem solid #3eb9ff;
  box-shadow: inset 0 0 1.5rem 0 #5987fc;
  background-color: rgba(10, 23, 56, .8);
}

.camera-item .close-icon:before {
  position: absolute;
  right: 16px;
  top: 16px;
  content: '✕'; /* 叉号的 Unicode 字符 */
  color: #fff; /* 设置颜色 */
  font-size: 20px;
  display: inline-block; /* 让伪元素变成行内元素 */
  margin-right: 5px; /* 可以根据需要调整叉号与文本的间距 */
}

</style>